import React from 'react';

/*
 * Displays the survey type based on the Done survey that was started
 */
export default settings => {
    return ({ value, row }) => {
        const youthAssignment = ['assignment36', 'assignment37'];
        const teenAssignment = ['assignment34', 'assignment35'];

        let type = '';

        let assignmentHandle = row['assignmentProgress.assignmentHandle'];
        if (youthAssignment.includes(assignmentHandle)) {
            type = 'youth';
        } else if (teenAssignment.includes(assignmentHandle)) {
            type = 'teen';
        }

        return <div>{type}</div>;
    };
};
