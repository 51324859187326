import React from 'react';
import PropTypes from 'prop-types';
import ClickButton from 'cccisd-click-button';
import { Formik, Form, Field, CccisdInput, CccisdTextarea, FieldArray } from 'cccisd-formik';
import style from './style.css';

const validate = values => {
    const errors = {};

    return errors;
};

export default class TrackingNumbersForm extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        initialValues: PropTypes.object,
        loadData: PropTypes.func.isRequired,
        closeModal: PropTypes.func,
    };

    onSubmit = async values => {
        await this.props.onSubmit(values);
        await this.props.loadData();
        this.props.closeModal();
    };

    render() {
        const { initialValues } = this.props;

        return (
            <Formik
                validate={validate}
                onSubmit={this.onSubmit}
                initialValues={initialValues}
                render={({ isSubmitting, handleSubmit, values }) => (
                    <Form>
                        <h3>{initialValues.clubName}</h3>
                        <h4>Club ID: {initialValues.clubId}</h4>
                        <p>Enter the shipping url used to track shipments. Use the full path. </p>
                        <p>
                            Example: &quot;https://www.fedex.com/apps/fedextrack/?action=track&amp;tracknumbers=&quot;
                        </p>

                        <FieldArray
                            name="trackingNumbers"
                            render={arrayHelpers => (
                                <div>
                                    {values.trackingNumbers.map((tn, index) => (
                                        <div key={index} className={style.tracking}>
                                            <div className="row">
                                                <div className="col-10">
                                                    <Field
                                                        name={`trackingNumbers.${index}.trackingNumber`}
                                                        label="Tracking Number"
                                                        component={CccisdInput}
                                                    />
                                                    <Field
                                                        name={`trackingNumbers.${index}.url`}
                                                        label="Shipping URL"
                                                        component={CccisdTextarea}
                                                    />
                                                </div>
                                                <div className="col-2">
                                                    <button
                                                        // insert empty fields above position
                                                        type="button"
                                                        className="btn btn-secondary btn-xs"
                                                        onClick={() => arrayHelpers.insert(index, '')}
                                                    >
                                                        +
                                                    </button>
                                                    <button
                                                        // remove trackingNumber from the list
                                                        type="button"
                                                        className="btn btn-secondary btn-xs"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                    >
                                                        -
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        />

                        <div style={{ textAlign: 'right' }}>
                            <ClickButton
                                title="Submit"
                                className="btn btn-primary"
                                isLoading={isSubmitting}
                                onClick={handleSubmit}
                            />
                        </div>
                    </Form>
                )}
            />
        );
    }
}
