import React from 'react';
import PropTypes from 'prop-types';
import CountInput from './CountInput.js';
import isInt from 'validator/lib/isInt';
import style from './style.css';
import { connect } from 'react-redux';
import { getAllowPaper } from 'js/selectors/params.js';
import { getTrackingNumbers } from 'js/selectors/admin.js';

export const fields = [
    'allPaperOrder',
    'paperOrderEnglishYouth',
    'paperOrderSpanishYouth',
    'paperOrderEnglishTeen',
    'paperOrderSpanishTeen',
];

export const validate = (values, props) => {
    const errors = {};

    const orderFields = [
        'paperOrderEnglishYouth',
        'paperOrderSpanishYouth',
        'paperOrderEnglishTeen',
        'paperOrderSpanishTeen',
    ];

    orderFields.forEach(field => {
        if (typeof values[field] === 'undefined') {
            errors[field] = 'Number is required';
        } else if (!isInt(values[field].toString(), { min: 0, max: 100 })) {
            errors[field] = 'Value has to be a number from 0 to 100.';
        }
    });

    if (Object.keys(errors).length === 0) {
        const totalPapers = orderFields.reduce((sum, field) => sum + +values[field], 0);
        if (totalPapers === 0) {
            errors.allPaperOrder = 'You must enter a number from 1-100 for at least one survey.';
        }

        const maxYouth = props?.club?.maxYouth || 0;
        const maxTeen = props?.club?.maxTeen || 0;

        if (maxYouth > 0 && Number(values.paperOrderEnglishYouth) + Number(values.paperOrderSpanishYouth) > maxYouth) {
            errors.allPaperOrder = `Maximum ${maxYouth} youth paper surveys are allowed`;
        } else if (
            maxTeen > 0 &&
            Number(values.paperOrderEnglishTeen) + Number(values.paperOrderSpanishTeen) > maxTeen
        ) {
            errors.allPaperOrder = `Maximum ${maxTeen} teen paper surveys are allowed`;
        }
    }

    return errors;
};

class Form extends React.Component {
    static propTypes = {
        fields: PropTypes.object.isRequired,
        allowPaper: PropTypes.bool,
        trackingNumbers: PropTypes.array,
    };

    render() {
        const {
            fields: {
                paperOrderEnglishYouth,
                paperOrderSpanishYouth,
                paperOrderEnglishTeen,
                paperOrderSpanishTeen,
                allPaperOrder,
            },
            allowPaper,
            trackingNumbers,
        } = this.props;
        const disableCounts = !allowPaper || trackingNumbers.length > 0;

        return (
            <div>
                <label className="form-label">How many paper surveys will you need?</label>
                <div className={style.info}>
                    <p>
                        The survey totals displayed below reflect the number of surveys <strong>returned</strong> by the
                        Club last year.
                    </p>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className={style.picture + ' ' + style.youth} />
                        <div className="text-center">Youth Surveys</div>
                        <div className={style.methodGrid}>
                            <CountInput field={paperOrderEnglishYouth} label="English" disabled={disableCounts} />
                            <CountInput field={paperOrderSpanishYouth} label="Spanish" disabled={disableCounts} />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className={style.picture + ' ' + style.teen} />
                        <div className="text-center">Teen Surveys</div>
                        <div className={style.methodGrid}>
                            <CountInput field={paperOrderEnglishTeen} label="English" disabled={disableCounts} />
                            <CountInput field={paperOrderSpanishTeen} label="Spanish" disabled={disableCounts} />
                        </div>
                    </div>
                </div>
                {allPaperOrder.error && (
                    <div className="mb-3">
                        <div className="alert alert-danger" style={{ margin: 0 }}>
                            {allPaperOrder.error}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(state => ({
    allowPaper: getAllowPaper(state.app.params),
    trackingNumbers: getTrackingNumbers(state.app.admin),
}))(Form);
