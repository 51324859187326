import React from 'react';
import PropTypes from 'prop-types';
import { domOnlyProps } from 'cccisd-redux-form-helpers';
import style from './style.css';

export const fields = ['consentForm'];

export const validate = (values, props) => {
    const errors = {};

    if (!values.consentForm) {
        errors.consentForm = 'You must choose a permission type.';
    }

    return errors;
};

export default class Form extends React.Component {
    static propTypes = {
        fields: PropTypes.object.isRequired,
    };

    render() {
        const {
            fields: { consentForm },
        } = this.props;

        return (
            <div>
                <p>
                    Parental permission is required for the NYOI member survey. At this Club, which type of permission
                    forms do you plan on using?
                </p>
                {consentForm.touched && consentForm.error && <div className="text-danger">{consentForm.error}</div>}
                <div className="form-check form-check-inline">
                    <label className={'form-check-label ' + style.grid}>
                        <div>
                            <input
                                type="radio"
                                className="form-check-input"
                                {...domOnlyProps(consentForm)}
                                value="passive"
                                checked={consentForm.value === 'passive'}
                            />
                        </div>
                        <div>
                            <div>PASSIVE PERMISSION FORMS:</div>
                            <div className={style.desc}>
                                Requires the parent/guardian to sign a form only if they wish to opt-out their child
                                from participating in the survey.
                            </div>
                        </div>
                    </label>
                </div>

                <div className="form-check form-check-inline">
                    <label className={'form-check-label ' + style.grid}>
                        <div>
                            <input
                                type="radio"
                                className="form-check-input"
                                {...domOnlyProps(consentForm)}
                                value="active"
                                checked={consentForm.value === 'active'}
                            />
                        </div>
                        <div>
                            <div>ACTIVE PERMISSION FORMS:</div>
                            <div className={style.desc}>
                                Requires the parent/guardian to sign a form stating whether their child can or cannot
                                participate in the survey.
                            </div>
                        </div>
                    </label>
                </div>

                <div className={style.comment}>
                    <p>* After registration is complete, you can download permission forms in the Resource Center.</p>
                    <p>
                        * Please note that, regardless of permission type, members taking the survey outside the Club
                        will not receive any risky behavior items.
                    </p>
                </div>
            </div>
        );
    }
}
