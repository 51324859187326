import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { TextBlock, FieldBlock, domOnlyProps } from 'cccisd-redux-form-helpers';
import states from 'js/config/states.js';
import _isEqual from 'lodash/isEqual';
import ClickButton from 'cccisd-click-button';
import style from './style.css';

const fields = ['address1', 'address2', 'city', 'state', 'zip'];

export const validate = values => {
    const errors = {};

    if (!values.address1) {
        errors.address1 = 'Address is required';
    }
    if (!values.city) {
        errors.city = 'City is required';
    }
    if (!values.state) {
        errors.state = 'State is required';
    }
    if (!values.zip) {
        errors.zip = 'Zip code is required';
    }

    const poRegex = /\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i;
    if (poRegex.test(values.address1)) {
        errors.address1 =
            'Warning! Shipping vendors cannot deliver materials to P.O. Box addresses. Please provide the physical address for the organization to ensure each shipment is received.';
    }
    if (poRegex.test(values.address2)) {
        errors.address2 =
            'Warning! Shipping vendors cannot deliver materials to P.O. Box addresses. Please provide the physical address for the organization to ensure each shipment is received.';
    }

    return errors;
};

class Form extends React.Component {
    static propTypes = {
        showErrorsImmediately: PropTypes.bool,
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        values: PropTypes.object,
        valid: PropTypes.bool,
        updateData: PropTypes.func,
        submitButtonTitle: PropTypes.node,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
    };

    componentDidUpdate(prevProps) {
        if (this.props.updateData && !_isEqual(prevProps.values, this.props.values)) {
            this.props.updateData(this.props.values);
        }
    }

    render() {
        const {
            showErrorsImmediately,
            fields: { address1, address2, city, state, zip },
            handleSubmit,
            submitButtonTitle,
            submitting,
            disabled,
        } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <TextBlock
                    field={address1}
                    label="Address 1"
                    style={{ maxWidth: '500px' }}
                    showErrorsImmediately={showErrorsImmediately}
                    disabled={disabled}
                />
                <TextBlock field={address2} label="Address 2" style={{ maxWidth: '500px' }} disabled={disabled} />
                <div className={style.grid}>
                    <TextBlock
                        field={city}
                        label="City"
                        showErrorsImmediately={showErrorsImmediately}
                        disabled={disabled}
                    />
                    <FieldBlock field={state} label="State" showErrorsImmediately={showErrorsImmediately}>
                        <select className="form-select" {...domOnlyProps(state)} disabled={disabled}>
                            <option key="none" value="">
                                -- Choose state --
                            </option>
                            {states.map(item => (
                                <option key={item.abbreviation} value={item.abbreviation}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </FieldBlock>
                    <TextBlock
                        field={zip}
                        label="Zip code"
                        showErrorsImmediately={showErrorsImmediately}
                        disabled={disabled}
                    />
                </div>
                {submitButtonTitle && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ClickButton
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            isLoading={submitting}
                            isDisabled={this.props.disabled}
                            title={submitButtonTitle}
                        />
                        {disabled && (
                            <div className="text-warning" style={{ marginLeft: '0.5em' }}>
                                The deadline for updating shipping settings has passed.
                            </div>
                        )}
                    </div>
                )}
            </form>
        );
    }
}

export default reduxForm({
    form: 'AddressForm',
    fields,
    validate,
})(Form);
