import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { FieldBlock, domOnlyProps } from 'cccisd-redux-form-helpers';
import ClickButton from 'cccisd-click-button';
import Toggle from 'js/components/Toggle';
import { surveyMethodOptions } from 'js/components/ClubForm/config.js';
import ConsentFormBlock, { fields as consentFields, validate as consentValidate } from 'js/components/ConsentFormBlock';
import PaperOrderFormBlock, {
    fields as paperFields,
    validate as paperValidate,
} from 'js/components/PaperOrderFormBlock';
import SelectModulesFormBlock, {
    fields as selectModulesFields,
    validate as selectModulesValidate,
} from 'js/components/SelectModulesFormBlock';
import classnames from 'classnames';
import notification from 'cccisd-notification';
import style from './style.css';
import { connect } from 'react-redux';
import { getAllowPaper } from 'js/selectors/params.js';

const fields = ['participatingThisYear', 'surveyMethod', ...consentFields, ...selectModulesFields, ...paperFields];

export const validate = (values, props) => {
    let errors = consentValidate(values, props);

    if (values.participatingThisYear) {
        if (values.surveyMethod === 'paper') {
            errors = {
                ...errors,
                ...paperValidate(values, props),
            };
        }

        if (values.surveyMethod === 'online') {
            errors = {
                ...errors,
                ...selectModulesValidate(values, props),
            };
        }
    }

    return errors;
};

class Form extends React.Component {
    static propTypes = {
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        disableUpdating: PropTypes.bool,
        allowPaper: PropTypes.bool,
        club: PropTypes.object,
    };

    changeSurveyMethod = e => {
        const { surveyMethod } = this.props.fields;

        if (e.target.value === 'paper' && !this.props.allowPaper) {
            notification({
                message:
                    'Paper registration has closed. Please contact nyoisupport@3cisd.com if you have questions about the paper survey.',
                type: 'danger',
                duration: 10000,
            });
        } else if (e.target.value === 'paper' && !this.props.club.paperEligible) {
            notification({
                message: 'Club is not eligible for paper survey method.',
                type: 'danger',
                duration: 10000,
            });
        } else {
            surveyMethod.onChange(e);
        }
    };

    render() {
        const {
            fields: { participatingThisYear, surveyMethod },
            handleSubmit,
            submitting,
            disableUpdating,
        } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                {disableUpdating && (
                    <div className="alert alert-danger">
                        Surveys at this Club have started.
                        <br />
                        Club is locked for updating.
                    </div>
                )}
                <div className={classnames({ [style.disabled]: disableUpdating })}>
                    <FieldBlock field={participatingThisYear}>
                        <Toggle {...participatingThisYear} label="Participating this year" />
                    </FieldBlock>
                    {participatingThisYear.value && (
                        <div>
                            <FieldBlock field={surveyMethod} label="Survey method">
                                <select
                                    {...domOnlyProps(surveyMethod)}
                                    onChange={this.changeSurveyMethod}
                                    className="form-select"
                                    style={{ width: 'auto' }}
                                >
                                    {surveyMethodOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.name}
                                        </option>
                                    ))}
                                </select>
                            </FieldBlock>
                            <ConsentFormBlock {...this.props} />
                            {surveyMethod.value === 'online' && <SelectModulesFormBlock {...this.props} />}
                            {surveyMethod.value === 'paper' && <PaperOrderFormBlock {...this.props} />}
                        </div>
                    )}
                    <ClickButton
                        className="btn btn-primary"
                        onClick={handleSubmit}
                        isLoading={submitting}
                        isDisabled={disableUpdating}
                        title="Submit"
                    />
                </div>
            </form>
        );
    }
}

export default connect(state => ({
    allowPaper: getAllowPaper(state.app.params),
}))(
    reduxForm({
        form: 'EditClubByOrgAdminForm',
        fields,
        validate,
    })(Form)
);
